* {
  box-sizing: border-box;

  &:focus {
    outline: 2px solid rgb(0, 95, 204) !important;
  }
}
html,
body,
#root,
.rootInner {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: $font-family;
  overflow: hidden;
  font-weight: 300;
}

#texts {
  visibility: hidden;
  width: 80px;
  height: 80px;
  position: absolute;
  left: 0;
  top: 0;
}

button {
  font-family: $font-family;
  letter-spacing: 0.5px;
  font-size: 13px;
  border: none;
}

.bzk {
  display: flex;
  justify-content: flex-end;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

main {
  width: 100vw;
  height: 100vh;
  @media screen and (min-width: 601px) {
    padding-left: 250px;
    transition: padding-left 0.25s ease-in;
    &.fullscreen {
      padding-left: 0;
    }
  }
}

svg {
  cursor: move;
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: grab;
  letter-spacing: -0.1px;

  &:hover,
  &:focus {
    cursor: move;
    cursor: -webkit-grab;
    cursor: -moz-grab;
    cursor: grab;
  }

  &:active {
    cursor: move;
    cursor: -webkit-grabbing;
    cursor: -moz-grabbing;
    cursor: grabbing;
  }
}

.link,
.node {
  transition: opacity 0.25s ease-in 0.25s;
}
[data-step='1'] svg,
[data-step='3'] svg {
  .node,
  .trends,
  .effect,
  .lines,
  .item {
    pointer-events: none;
  }
}

[data-step='2'] {
  .node,
  .trend,
  .effect,
  .item {
    cursor: move;
    cursor: -webkit-grab;
    cursor: -moz-grab;
    cursor: grab;

    &:hover,
    &:focus {
      cursor: move;
      cursor: -webkit-grab;
      cursor: -moz-grab;
      cursor: grab;
    }

    &:active {
      cursor: move;
      cursor: -webkit-grabbing;
      cursor: -moz-grabbing;
      cursor: grabbing;
    }
  }
}
