.bzk-options {
	margin: 0;
	padding: 0;
	list-style: none;
	display: flex;
	flex-direction: column;
	height: 100%;
	li {
		display: flex;
		flex-direction: column;
		background-color: #fff;
		min-height: 40px;
		position: relative;
		z-index: 9;
		transition: flex 0.25s ease-in;
		height: auto;
		backface-visibility: hidden;
	}
}

.bzk-options__spinner {
	width: 15px;
	height: 15px;
	border: 2px solid transparent;
	border-top-color: #3c3c3c;
	border-radius: 20px;
	border-right: 0;
	display: inline-block;
	margin-left: 10px;
	top: 2px;
	position: relative;
	animation: spin 1s linear infinite;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.bzk-options__title {
	height: 40px;
	color: #3c3c3c;
	margin: 0;
	display: block;
	align-items: center;
	background-color: #f7f7f7;
	line-height: 40px;
	border-bottom: 1px solid #ddd;
	border-top: 1px solid #ddd;

	span {
		width: 40px;
		background-color: #eee;
		font-weight: bold;
		align-items: center;
		justify-content: center;
		display: inline-block;
		height: 100%;
		margin-right: 10px;
		text-align: center;
		&.checked {
			position: relative;
			&:before {
				content: '';
				position: absolute;
				left: 0;
				top: 0;
				right: 0;
				bottom: 0;
				background-color: #eee;
			}
			&:after {
				content: '';
				position: absolute;
				width: 10px;
				height: 4px;
				border-bottom: 2px solid #3c3c3c;
				border-left: 2px solid #3c3c3c;
				transform: rotate(-45deg) translate(-50%, -50%);
				top: 50%;
				left: 50%;
				margin-top: -8px;
			}
		}
	}
}

.bzk-options__active {
	flex: 1;

	.bzk-options__title {
		border-top: 0;
	}

	+ .bzk-options__inactive {
		.bzk-panel__content,
		.bzk-panel__footer {
			display: block;
		}
	}

	.bzk-panel__footer {
		overflow: auto;
	}
}

.bzk-options__inactive {
	&:first-child .bzk-options__title,
	+ .bzk-options__inactive .bzk-options__title {
		border-top: 0;
	}
	.bzk-panel__content,
	.bzk-panel__footer {
		display: none;
	}
}
