.bzk-loader {
	height: 100%;
	align-items: center;
	justify-content: center;
	display: flex;
	flex-direction: column;

	.bzk-options__spinner {
		margin: 0;
	}
}

.bzk-loader__title {
	font-weight: bold;
	align-self: center;
	font-size: 16px;
	margin-top: 5px;
}
