.bzk-notice {
  border: 1px solid #ccc;
  background-color: #eee;
  color: #444;
  padding: 5px 10px;
}

.bzk-notice--outside-root {
  position: fixed;
  left: 10px;
  top: 10px;
  right: 10px;
  z-index: 99;
}

.bzk-notice__mobile {
  position: fixed;
  z-index: 999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media screen and (min-width: 769px) {
    display: none;
  }
}

.bzk-notice__mobile-btn {
  margin-top: 10px;
  outline: none;
  padding: 10px;
  font-weight: bold;
  text-transform: uppercase;
  color: #3c3c3c;
  text-align: center;
  border-radius: 3px;
  background-color: #eee;
  box-shadow: 0 2px 0 #ddd;
  cursor: pointer;
  position: relative;
  font-size: 13px;
  align-self: center;

  + .bzk-notice__mobile-btn {
    margin-left: 20px;
  }

  &:hover,
  &:focus {
    opacity: 1;
    background-color: #ddd;
    box-shadow: 0 2px 0 #ccc;
    text-decoration: underline;
  }

  &:active {
    opacity: 1;
    box-shadow: 0 0 0 rgba(0, 0, 0, 0);
    top: 2px;
  }
}

.bzk-notice__content {
  a {
    color: currentColor;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  p {
    margin: 20px 0;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 40px 0 20px;
  }

  ul li:before {
    content: '•';
    margin-right: 5px;
  }

  + .bzk-notice__mobile-btn {
    margin-bottom: 100px;
  }
}

.bzk-notice__buttons {
  flex-direction: row;

  .bzk-panel__button {
    margin: 5px;
  }
}
