.bzk-pages {
	position: fixed;
	right: 20px;
	bottom: 20px;
	display: flex;
	z-index: 2;
	align-items: flex-end;
	flex-direction: row-reverse;

	@media screen and (min-height: 400px) {
		flex-direction: column;
	}
}

.bzk-pages__button {
	margin-left: 5px;
	font-size: 12px;
	text-decoration: underline;
	margin-top: 5px;
	cursor: pointer;
	outline: none;
	color: #777;
	background-color: #fff;

	&:hover {
		opacity: 0.75;
	}
}

.bzk-pages__logo-link {
	text-decoration: none;
	outline: none;
}

.bzk-pages__logo {
	width: 60px;
	display: block;
	@media screen and (min-width: 769px) {
		width: 100px;
	}

	&:hover {
		opacity: 0.75;
	}
}
