.bzk-panel {
  position: fixed;
  top: 0px;
  left: 0px;
  bottom: 0;
  background-color: #fff;
  transition: transform 0.25s ease-in;
  width: 100%;
  z-index: 2;
  display: flex;
  flex-direction: column;
  z-index: 3;

  &.closed {
    transform: translateX(-100%);
  }

  @media screen and (min-width: 601px) {
    border-right: 1px solid #ddd;
    width: 250px;

    &.closed {
      transform: translateX(-250px);
    }
  }
}

.bzk-panel__close {
  position: absolute;
  right: -1px;
  top: 49px;
  height: 41px;
  border-radius: 4px 0 0 4px;
  background-color: #fff;
  width: 40px;
  margin-right: 0px;
  border: 1px solid #ddd;
  outline: none;
  z-index: 10;
  &:focus,
  &:active,
  &:hover {
    background-color: #eee;
    text-decoration: underline;

    &:before {
      opacity: 1;
    }
  }

  .closed & {
    margin-right: -38px;
    border-radius: 0 4px 4px 0;
    @media screen and (min-width: 601px) {
      margin-right: -20px;
    }
  }

  @media screen and (min-width: 601px) {
    top: 39px;
    right: 0;
    width: 20px;
    margin-right: -20px;
    border-radius: 0 4px 4px 0;
  }

  &:before {
    content: '';
    width: 8px;
    height: 1px;
    background-color: #222;
    position: absolute;
    left: 4px;
    bottom: 12px;
    opacity: 0;
  }

  &:after {
    content: '';
    border: 4px solid transparent;
    border-right: 6px solid #222;
    position: relative;
    z-index: 3;
    display: block;
    left: -9px;

    .closed & {
      left: 9px;
      border-right: 4px solid transparent;
      border-left: 6px solid #222;
      @media screen and (min-width: 601px) {
        left: 0px;
      }
    }
    @media screen and (min-width: 601px) {
      left: -6px;
    }
  }
}

.bzk-panel__content {
  height: 100%;
  overflow: auto;
  padding: 15px;
  flex: 1;
  max-height: 100vh;
}

.bzk-panel__list {
  margin: 0 0 30px;
  padding: 0;
  list-style: none;

  &:last-child {
    margin-bottom: 0;
  }
}

.bzk-panel__title {
  text-transform: uppercase;
  font-size: 16px;
  color: #222;
  font-weight: bold;
  margin-bottom: 5px;
  position: relative;
  font-family: $font-family;

  button {
    display: none;
    @media screen and (min-width: 601px) {
      display: block;
      padding: 0;
      background-color: #fff;
      outline: none;
      font-weight: bold;
      border-radius: 11px;
      width: 22px;
      height: 22px;
      line-height: 20px;
      position: absolute;
      right: 0;
      top: 0;
      border: 1px solid #ccc;
      cursor: pointer;
      text-align: center;
      &:focus,
      &:active,
      &:hover {
        border-color: #aaa;
        background-color: #eee;
        text-decoration: underline;
      }
    }
  }
}

.bzk-panel__title--count {
  font-size: 12px;
  font-weight: 300;
  float: right;
  margin-right: 10px;
}

.bzk-panel__button {
  padding: 0 20px 0 0;
  border: none;
  background-color: transparent;
  outline: none;
  font-size: 13px;
  position: relative;
  margin-bottom: 10px;
  border-radius: 50px;
  font-weight: 300;
  text-align: left;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  width: 100%;
  font-family: $font-family;
  cursor: pointer;
  &:focus,
  &:active,
  &:hover {
    background-color: #f7f7f7;
    text-decoration: underline;
  }

  &:active {
    box-shadow: 0 0 2px 2px rgba(199, 199, 199, 0.5);
  }

  &.active {
    background-color: #eee;
    cursor: default;

    &:after {
      padding: 0;
      content: '×';
      font-size: 16px;
      height: 15px;
      position: absolute;
      right: 7px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
    }
  }

  [data-active-step='2'] &,
  [data-active-step='3'] & {
    pointer-events: none;
  }
}

.bzk-panel__button--secondary {
  width: auto;
  margin: 10px auto 0;
  border: 1px solid #ccc;
  text-align: center;
  padding: 5px 10px;
  border-radius: 3px;
  height: auto;
  display: inline-block;
}

.bzk-panel__button--no-img {
  &:before {
    content: '';
    min-width: 36px;
    height: 36px;
    background-color: #fff;
    border-color: #eee;
    border-width: 2px;
    border-radius: 40px;
    display: inline-block;
    margin-right: 10px;
    border-style: solid;
  }

  &[data-id='large']:before,
  &[data-id='medium']:before {
    border-color: #000;
  }
  &[data-id='large']:before {
    border-style: dashed;
  }
}

.bzk-panel__icon-holder {
  border-radius: 40px;
  min-width: 40px;
  min-height: 40px;
  width: 40px;
  height: 40px;
  max-width: 40px;
  max-height: 40px;
  vertical-align: middle;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 2px;

  svg {
    width: 20px;
    height: 20px;
    display: block;
    margin: 0 auto;
  }
}

.bzk-panel__button--consequences .bzk-panel__icon-holder {
  position: relative;

  &:before {
    border-radius: 40px;
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    border: 3px solid rgba(0, 0, 0, 0.1);
  }
}

.bzk-panel__icon {
  width: 20px;
  height: 20px;
  object-fit: contain;
}

.bzk-panel__item-active {
  opacity: 1;
}

.bzk-panel__item-inactive {
  opacity: 0.25;
}

.bzk-panel__outline {
  width: calc(50% - 20px);
  border: 1px solid #eee;
  border-radius: 10px;
  padding: 10px;
  margin: 10px;
  float: left;

  &:nth-child(even) {
    clear: left;
  }

  h4 {
    margin: 0 0 10px;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: 14px;
    color: #666;
    font-weight: 300;

    .bzk-panel__in-filter {
      color: #222;
      font-weight: 600;
    }

    span {
      width: 80px;
      display: inline-block;
      padding-right: 5px;
    }
  }
}

.bzk-panel__footer {
  width: 100%;
  z-index: 9;
  padding: 15px;

  p {
    margin: 0;
    font-size: 15px;
  }

  button {
    padding: 10px;
    border: none;
    outline: none;
    font-weight: bold;
    text-transform: uppercase;
    color: #3c3c3c;
    text-align: center;
    width: 100%;
    margin-top: 10px;
    cursor: pointer;

    &:hover,
    &:focus,
    &:active {
      opacity: 0.5;
    }
  }

  .bzk-panel__next {
    margin-top: 10px;
    outline: none;
    padding: 10px;
    font-weight: bold;
    text-transform: uppercase;
    color: #3c3c3c;
    text-align: center;
    width: 100%;
    border-radius: 3px;
    background-color: #eee;
    box-shadow: 0 2px 0 #ddd;
    cursor: pointer;
    position: relative;

    &[disabled] {
      opacity: 0.5;
      cursor: not-allowed;
    }

    &:not([disabled]):hover,
    &:not([disabled]):focus {
      opacity: 1;
      background-color: #ddd;
      box-shadow: 0 2px 0 #ccc;
      text-decoration: underline;
    }

    &:not([disabled]):active {
      opacity: 1;
      box-shadow: 0 0 0 rgba(0, 0, 0, 0);
      top: 2px;
    }
  }

  .bzk-panel__link {
    bottom: 15px;
    position: absolute;
    left: 15px;
    right: 15px;
    width: auto;
    text-decoration: none;
    font-size: 13px;

    &:not([disabled]):active {
      opacity: 1;
      box-shadow: 0 0 0 rgba(0, 0, 0, 0);
      top: auto;
      bottom: 13px;
    }
  }
}

.bzk-panel__footer--mb p {
  margin-bottom: 15px;
}
