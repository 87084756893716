.bzk-canvas {
  padding: 20px;
  padding-left: 350px;
}

.bzk-canvas__section {
  padding: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.bzk-canvas__noresults {
  position: fixed;
  background-color: #fff;
  left: 250px;
  top: 0;
  right: 0;
  bottom: 0;
  transition: left 0.25s ease-in;
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding: 30px 0;
  align-items: center;

  .fullscreen & {
    left: 0;
  }
}

.bzk-canvas__noresults--inner {
  max-width: 600px;
  text-align: center;
  padding: 0 20px;
  margin: auto;

  h2 {
    font-weight: bold;
    text-transform: uppercase;
    margin: 0;
    font-size: 24px;
    padding: 5px;
  }

  p {
    font-size: 16px;
    line-height: 1.4;
    padding: 5px;
    margin: 0 0 10px;
  }
}

.bzk-canvas__controls {
  display: none;
  @media screen and (min-width: 769px) {
    position: fixed;
    right: 20px;
    top: 20px;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.bzk-canvas__control {
  font-family: arial, sans-serif;
  width: 34px;
  height: 16px;
  font-weight: 300;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 3px;
  outline: none;
  cursor: pointer;
  position: absolute;
  color: #222;

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &:not([disabled]):hover {
    background-color: #eee;
  }

  &:focus,
  &:active {
    background-color: #eee;
    border-color: #aaa;
  }
}

.bzk-canvas__control--plus {
  top: 36px;
  right: 36px;
}

.bzk-canvas__control--min {
  top: 54px;
  right: 36px;
  font-size: 20px;
}

.bzk-canvas__control--arrow {
  height: 34px;
  font-size: 0px;
  color: transparent;
  &:before {
    content: '';
    position: absolute;
  }
}

.bzk-canvas__control--up {
  top: 0;
  right: 36px;

  &:before {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #222;
  }
}

.bzk-canvas__control--down {
  top: 72px;
  right: 36px;

  &:before {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #222;
  }
}

.bzk-canvas__control--left {
  top: 36px;
  right: 72px;

  &:before {
    border-bottom: 5px solid transparent;
    border-right: 5px solid #222;
    border-top: 5px solid transparent;
  }
}

.bzk-canvas__control--right {
  top: 36px;
  right: 0;

  &:before {
    border-bottom: 5px solid transparent;
    border-left: 5px solid #222;
    border-top: 5px solid transparent;
  }
}
